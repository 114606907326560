import React from "react";
import "./ChatGuide.scss";
import MailChatExample from "./MailChatExample";

const MailChatGuide = () => {
  return (
    <div className="chat-guide">
      <ul>
        <li>
          개선하고 싶은 이메일 내용을 입력해 주시면, 챗봇이 더 공손한 톤으로
          변환해 드립니다.
        </li>
        <li>
          <strong>중요:</strong> 대화 형식이 아닌, 메일 내용만 입력해 주세요.
        </li>
      </ul>
      <MailChatExample />
    </div>
  );
};

export default MailChatGuide;
