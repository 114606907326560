import React from "react";
import assistantIcon from "../../images/mail.png";

function AuthContainer({ mainError }) {
  return (
    <div className="auth-container">
      <div className="auth-form">
        <img src={assistantIcon} alt="Assistant" className="auth-icon" />
        <p>{mainError}</p>
      </div>
    </div>
  );
}

export default AuthContainer;
