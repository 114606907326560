import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineZoomIn } from "react-icons/ai";
import { AiOutlineZoomOut } from "react-icons/ai";
import "./FileViewer.scss";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// PDF.js 워커 설정
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FileViewer = ({ fileUrl, fileName, pages, isOpen, onClose }) => {
  const [pdfContent, setPdfContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.0);
  const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 2));
  const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (isOpen && fileUrl) {
      setIsLoading(true);
      fetch(fileUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.arrayBuffer();
        })
        .then((arrayBuffer) => {
          const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setPdfContent(`data:application/pdf;base64,${base64}`);
          setError(null);
        })
        .catch((error) => {
          console.error("Error fetching PDF:", error);
          setError(`Error loading PDF: ${error.message}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isOpen, fileUrl]);

  if (!isOpen) return null;

  return (
    <>
      {" "}
      {error ? (
        <div className="file-viewer-overlay" onClick={onClose}>
          <div className="error">
            <p>
              {" "}
              🔎 죄송합니다. 지금은 문서를 열 수 없습니다. <br></br>인트라넷
              환경의 PC에서 다시 시도해 주세요.
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="file-viewer-overlay" onClick={onClose}></div>
          <div className="file-viewer">
            <div className="file-viewer-header">
              <div id="file-viewer-title">
                📂 {fileName}
                <br></br>
                <i>pages: {pages.toString()}</i>
                <br></br>
                <button id="zoom" onClick={zoomIn}>
                  <AiOutlineZoomIn />
                </button>
                <button id="zoom" onClick={zoomOut}>
                  <AiOutlineZoomOut />
                </button>
              </div>
              <button onClick={onClose}>
                <IoMdClose />
              </button>
            </div>
            <div className="file-viewer-content">
              {isLoading && <div>Loading PDF...</div>}
              {pdfContent && (
                <>
                  <Document
                    file={pdfContent}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={{
                      cMapUrl: "cmaps/",
                      cMapPacked: true,
                    }}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        scale={scale}
                        renderTextLayer={true}
                        renderAnnotationLayer={true}
                      />
                    ))}
                  </Document>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FileViewer;
