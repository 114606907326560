import React, { useRef, useEffect } from "react";
import "../../App.scss";

const GeneratingMessage = ({ content }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [content]);

  return (
    <div ref={containerRef} className="generating-message-container">
      {content.split("\n").map((line, index) => (
        <p key={index} className="generating-line">
          {line}
        </p>
      ))}
    </div>
  );
};

export default GeneratingMessage;
