import React, { useEffect, useState } from "react";
import "./FAQ.scss";
import { PiWechatLogo } from "react-icons/pi";
import axios from "axios";

const FAQ = ({ currentServer, onSelectFAQ, company }) => {
  const [selectedItems, setSeletedItems] = useState([]);
  useEffect(() => {
    axios
      .post(`${currentServer}/ml2_llm_service/get_faqs`)
      .then((response) => {
        if (response.status !== 200) {
          return;
        }
        if (response.data) {
          let filteredData = response.data.filter(
            (item) => item[0] === company
          );
          setSeletedItems(filteredData.map((arr) => arr[1]));
          console.log(company);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="FAQ-container">
      <div className="FAQ-title">
        {selectedItems.length > 0 ? (
          <>
            <PiWechatLogo /> 자주 물어보는 질문이에요
          </>
        ) : (
          <>
            <b>{company}</b> 사규가 없습니다. 😢
          </>
        )}
      </div>
      {selectedItems.map((item, index) => (
        <button
          key={index}
          className="FAQ-box"
          onClick={() => onSelectFAQ(item)}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

export default FAQ;
