import React, { useState, useEffect, useRef } from "react";
import "./App.scss";
import Sidebar from "./Components/Sidebar/Sidebar";
import { TfiMenuAlt } from "react-icons/tfi";
import FileViewer from "./Components/FileViewer/FileViewer";
import AuthContainer from "./Components/AuthContainer/AuthContainer.jsx";
import ChatContainer from "./Components/ChatContainer/ChatContainer.jsx";
import MailChatContainer from "./Components/ChatContainer/MailChatContainer.jsx";
import OnboardingGuide from "./Components/OnboardingGuide/OnboardingGuide.jsx";
import axios from "axios";
import { SERVER_URL, DEV_SERVER_URL } from "./config";

function App() {
  // const [messages, setMessages] = useState([
  //   {
  //     role: "assistant",
  //     content: "무엇이 궁금하신가요?",
  //     isFeedbackEnabled: false,
  //   },
  // ]);
  const [mainError, setMainError] = useState("");
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDevMode, setIsDevMode] = useState(false);
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [isRAG, setIsRAG] = useState(true);
  const [showGuide, setShowGuide] = useState(true);
  const controllerRef = useRef(null);
  const userRef = useRef(null); // Track user ID
  const isAuthenticatingRef = useRef(false); // Track if user is being authenticated
  const currentServerRef = useRef(SERVER_URL);

  useEffect(() => {
    if (isAuthenticatingRef.current) return;
    isAuthenticatingRef.current = true;
    const query = new URLSearchParams(window.location.search);
    const token = query.get("kcparams");
    if (token) {
      // // 백엔드에 authorization code 전송
      axios
        .post(
          `${currentServerRef.current}/auth_service/kc_group_user_verification`,
          {
            token,
          }
        )
        .then((response) => {
          if (response.status !== 200) {
            return;
          }
          if (response.data) {
            console.log("User verified:", response.data);
            userRef.current = response.data;
            setAuthenticated(true);
          }
        })
        .catch((error) => {
          setMainError("서버가 응답하지 않습니다.");
        })
        .finally(() => {
          isAuthenticatingRef.current = false;
        });
    } else {
      setMainError("인증이 필요합니다.");
    }
    return () => {
      stopGenerating();
    };
  }, []);

  // 스트림 중지 함수
  const stopGenerating = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      controllerRef.current = null;
      setIsGenerating(false);
    }
  };

  // OnboardingGuide
  const handleGuideComplete = () => {
    setShowGuide(false);
    // localStorage.setItem("guideCompleted", "true");
  };

  const handleOpenGuide = () => {
    setShowGuide(true);
  };

  // PDF sidebar
  const handleOpenFile = (fileUrl, fileName, pages) => {
    setCurrentFile({ url: fileUrl, name: fileName, pages: pages });
    setIsFileViewerOpen(true);
  };

  const handleCloseFile = () => {
    setIsFileViewerOpen(false);
  };

  // Inquiry sidebar
  const openSidebar = () => {
    setIsSidebarOpen(true);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  // switch to devMode
  const handleDevMode = () => {
    setIsDevMode(!isDevMode);
    currentServerRef.current = isDevMode ? SERVER_URL : DEV_SERVER_URL;
  };

  // switch to MailChatContainer
  const handleOpenMailChat = () => {
    setIsRAG(false);
  };

  const handleOpenRAGChat = () => {
    setIsRAG(true);
  };

  return (
    <div>
      {!isAuthenticated ? (
        <AuthContainer mainError={mainError} />
      ) : (
        <div
          className={`app-container ${
            isFileViewerOpen ? "file-viewer-open" : ""
          }`}
        >
          {isSidebarOpen && (
            <Sidebar
              toggleSidebar={closeSidebar}
              userInfo={userRef.current}
              isRAG={isRAG}
              handleOpenRAGChat={handleOpenRAGChat}
              handleOpenMailChat={handleOpenMailChat}
            />
          )}
          {!isSidebarOpen && (
            <button onClick={openSidebar} className="open-sidebar">
              <TfiMenuAlt />
            </button>
          )}
          {showGuide && (
            <OnboardingGuide
              onComplete={handleGuideComplete}
              userInfo={userRef.current}
              startRAG={handleOpenRAGChat}
              startMail={handleOpenMailChat}
            />
          )}
          {isRAG ? (
            <ChatContainer
              isSidebarOpen={isSidebarOpen}
              isDevMode={isDevMode}
              handleDevMode={handleDevMode}
              handleOpenFile={handleOpenFile}
              currentServer={currentServerRef.current}
              userInfo={userRef.current}
              isRAG={isRAG}
              handleOpenRAGChat={handleOpenRAGChat}
              handleOpenMailChat={handleOpenMailChat}
              handleOpenGuide={handleOpenGuide}
            />
          ) : (
            <MailChatContainer
              isSidebarOpen={isSidebarOpen}
              isDevMode={isDevMode}
              handleDevMode={handleDevMode}
              handleOpenFile={handleOpenFile}
              currentServer={currentServerRef.current}
              userInfo={userRef.current}
              isRAG={isRAG}
              handleOpenRAGChat={handleOpenRAGChat}
              handleOpenMailChat={handleOpenMailChat}
              handleOpenGuide={handleOpenGuide}
            />
          )}

          {isFileViewerOpen && (
            <FileViewer
              fileUrl={currentFile.url}
              fileName={currentFile.name}
              pages={currentFile.pages}
              isOpen={isFileViewerOpen}
              onClose={handleCloseFile}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default App;
